<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="单位简称">
              <a-input v-model="queryParam.short_name"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用类型">
              <a-select
                showSearch
                allowClear
                placeholder="请选择费用类型"
                style="width: 100%"
                v-model="queryParam.fee_type"
              >
                <a-select-option v-for="item in feeTypeMaps" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="fee_type" slot-scope="text">
        {{ feeTypeMaps[text] && feeTypeMaps[text]['name'] }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>
    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '创建成本支付单位' : '编辑成本支付单位'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="单位简称">
              <a-input v-model="form.short_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="单位全称" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="费用类型">
              <a-select v-model="form.fee_type" showSearch
                        ref="fee"
                        allowClear style="width: 100%">
                <a-select-option v-for="item in feeTypeMaps" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="联系人">
              <a-input v-model="form.contact" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="联系电话">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" placeholder="" :rows="3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getMetaInfoPage, deleteMetaTypeInfo, createMetaTypeInfo, updateMetaTypeInfo } from '@/api/wms/meta'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      feeTypeMaps: {
        1: { value: 1, name: '应收' },
        2: { value: 2, name: '应付' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '单位简称',
          dataIndex: 'short_name'
        },
        {
          title: '单位全称',
          dataIndex: 'name'
        },
        {
          title: '费用类型',
          dataIndex: 'fee_type',
          scopedSlots: { customRender: 'fee_type' }
        },
        {
          title: '联系人',
          dataIndex: 'contact'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '120px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        // if (!parameter.orderby) {
        //   parameter.order = 'desc'
        //   parameter.orderby = 'created_at'
        // }
        return getMetaInfoPage('pay_target', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        short_name: '',
        name: '',
        fee_type: 1,
        contact: '',
        mobile: '',
        remark: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入地点名称', trigger: 'blur' }
        ]
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create'
    }
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        short_name: '',
        name: '',
        fee_type: 1,
        contact: '',
        mobile: '',
        remark: ''
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('pay_target', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createMetaTypeInfo
          if (this.modalFlag === 'update') {
            func = updateMetaTypeInfo
          }
          func('pay_target', this.form).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              if (this.modalFlag === 'create') {
                this.$refs.table.refresh(true)
              }
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
